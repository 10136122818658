<template>
	<div class="enjoy" v-if="isdiv">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="恭喜函"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11" 
		:border="false"
		placeholder
		>
		</van-nav-bar>
		<div class="enjoy_concat">
			<div class="enjoy_concat_title">
				{{title1}}
			</div>
			
			
			<!-- <div class="imaglist">
				<image v-for="v,index in imgsliat" :key="index" :src="v" mode=""></image>
			</div> -->
		</div>
		
		<van-image class="driveinfo_top" :src="serverUrl(invitationinfo[1].images)" mode="widthFix"></van-image>
		
		<div class="enjoy_concat_us">
			{{invitationinfo[2].content[0]}}
		</div>
		
		<div class="enjoy_concat_list">
			<div v-for="v,index in invitationinfo[3].serverinfo" :key="index" class="">
				{{v[0]}}
				<span>{{v[1]}}</span>
			</div>
		</div>
		
		<van-image class="imgswx1" :src="serverUrl(invitationinfo[4].images)" mode="widthFix"></van-image>
		<van-image class="imgswx2" :src="serverUrl(invitationinfo[5].images)" mode="widthFix"></van-image>
		
	</div>
</template>

<script>

	export default {
		data() {
			return {
				scrollTop:0,
				id:'',
				name:'',
				title1:'',
				isdiv:false,
				form:{},
				invitationinfo:[],
				isshare:false,
				invitation_id:'',
				imgsliat:['http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx1.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx2.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx3.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx4.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx5.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx6.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx7.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx8.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx9.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx10.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx11.png','http://hm-h5-1.oss-cn-hangzhou.aliyuncs.com/imm-2212-scsj/icon_gx12.png'],
			}
		},
		mounted(){
			this.id = this.$route.query.id
			this.getInvitationList()
		},
		watch:{
			name(val1,val2){
				this.title1 = this.invitationinfo[0].content[0].replace("{客户称呼}",val1)
			}
		},
		methods:{
			async getInvitationList() {
				const {
					data: res1
				} = await this.$http.post('/api/scsj/h5_get_invitation_info_1_7_1',{id:this.id})
				if (res1.code == 1) {
					this.invitationinfo = res1.data.res.scsj_invitation_formwork_content
					this.title1 = this.invitationinfo[0].content[0]
					this.isdiv = true
				}	
			},
		}
	}
</script>

<style lang="scss" scoped>
	.enjoy{
		padding-bottom: 200px;
	}
.enjoy_concat{
	padding: 32px;
}
.enjoy_concat_title{
	font-size: 36px;
	font-family: HYQiHeiY3-55;
	font-weight: normal;
	color: #FFFFFF;
}

.driveinfo_line{
		margin-top: 42px;
		height: 1px;
		background-color: #919191;
	}
.enjoy_concat_us{
	padding: 32px;
	font-size: 36px;
	font-family: HYQiHeiY3-55;
	font-weight: normal;
	color: #FFFFFF;
	line-height: 52px;
}
.enjoy_concat_list{
	padding: 32px;
	
	div{
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-top: 30px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 44px;
	}
}
.input_name{
	margin-top: 25px;
	height: 88px;
	background: #2D2D2D;
	padding: 0 30px;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	font-weight: normal;
	color: #ECECEC;;
	line-height: 88px;
}

.imaglist{
	padding: 20px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	image{
		width: 332px;
		height: 264px;
		margin-top: 20px;
	}
}

.imgswx1{
	width: 100%;
}

.imgswx2{
	width: 100%;
}


.user_footer{
	z-index: 2;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 182px;
	background: #000000;
	text-align: center;
	div{
		width: 670px;
		height: 92px;
		background: #FA0037;
		text-align: center;
		margin: 30px auto 0;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}
.driveinfo_top{
	width: 100%;
}

</style>